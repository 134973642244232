export default [
  {
    path: 'listings',
    name: 'Listings',
    component: () => import(/* webpackChunkName: "listings" */ '@/views/Listings/List/index.vue'),
    meta: {
      title: 'listings',
      requiresAuth: true,
      agent: true,
    }
  }, {
    path: 'listings/deals',
    name: 'DealListing',
    component: () => import(/* webpackChunkName: "listings" */ '@/views/Listings/List/index.vue'),
    meta: {
      title: 'deal_listings',
      requiresAuth: true,
      agent: true,
    }
  }, {
    path: 'listings/deals/:listingConfigId',
    name: 'DealListingDetails',
    component: () => import(/* webpackChunkName: "listing_details" */ '@/views/Listings/Details/index.vue'),
    meta: {
      title: 'deal_listing_details',
      requiresAuth: true,
      agent: true,
    }
  }, {
    path: 'listings/leads',
    name: 'LeadListing',
    component: () => import(/* webpackChunkName: "listings" */ '@/views/Listings/List/index.vue'),
    meta: {
      title: 'lead_listings',
      requiresAuth: true,
      agent: true,
    }
  }, {
    path: 'listings/leads/:listingConfigId',
    name: 'LeadListingDetails',
    component: () => import(/* webpackChunkName: "listing_details" */ '@/views/Listings/Details/index.vue'),
    meta: {
      title: 'lead_listing_details',
      requiresAuth: true,
      agent: true,
    }
  }
]